import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";
import { UsersList } from "./components/users/UsersList";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { UserDetail } from "./components/users/UserDetail.js";
import { ContactList } from "./components/contact/ContactList.js";
import { ExcessiveList } from "./components/excessiveUsage/ExcessiveList.js";



function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  useEffect(() => {
    if (getItem("accessToken")) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest }}>
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/user-list"
            element={
              <ProtectedRoute>
                <UsersList />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/user/:id"
            element={
              <ProtectedRoute>
                <UserDetail />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/excessive-usage"
            element={
              <ProtectedRoute>
                <ExcessiveList />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          >

          </Route>

          <Route
            path="/contact-list"
            element={
              <ProtectedRoute>
                <ContactList />
              </ProtectedRoute>
            }
          >
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}

      </>
    </MyContext.Provider>
  );
}

export default App;
