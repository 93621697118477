import React, {useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../config/config";
const Sidebar = ({ isOpen, setIsOpen }) => {
  const {affiliateRequest, setAffiliateRequest}=useContext(MyContext);
  const location=useLocation()
  const navigate = useNavigate();
  return (
    <>
      <div className="sidebar-overlay">
      {/* <div className="head-menu">
          <img src={require(`../assets/images/${isOpen ? 'menu.png' : 'menu-close.png'}`)} alt="menu" onClick={() => setIsOpen(!isOpen)} />
      </div> */}
        <section className="sidebar">
        <div className="logo" role="button" onClick={(e) => navigate("/user-list")}>
                <img src={require("../assets/images/logo.png")} alt="" />
              </div>
          <div className={isOpen ? "sidebar-open" : "sidebar-close"}>
            <Link to="/user-list" className={(location.pathname == "/user-list" || location.pathname.includes("/user/")) ? "sidebar_option active": "sidebar_option"} >
            <i className="fa fa-users" aria-hidden="true"></i>
              User Management
            </Link>
            <Link to="/excessive-usage" className={(location.pathname == "/excessive-usage") ? "sidebar_option active": "sidebar_option"} >
            <i className="fa fa-user-plus" aria-hidden="true"></i>
              Excessive Usage Customers
            </Link>
            <Link to="/contact-list" className={(location.pathname == "/contact-list") ? "sidebar_option active": "sidebar_option"} >
            <i className="fa fa-phone" aria-hidden="true"></i>
              Contact Management
            </Link>
          </div>
          
          
        </section>
      </div>
    </>
  );
};
export default Sidebar;
